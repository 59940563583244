import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from 'src/i18n';
import { Router } from 'react-router-dom';
import history from 'src/utils/history';
import { AuthProvider } from 'src/providers/AuthProvider';
import { UserContext, UserProvider } from 'src/providers/UserProvider';
import { customerTheme, taskerTheme } from 'src/styles/theme';
import { ThemeProvider } from 'styled-components';
import { Grommet } from 'grommet';
import { NotificationProvider } from 'src/providers/NotificationProvider';
import App from 'src/features/App/App';
import { ApolloProvider } from '@apollo/client';
import Amplify, { Hub } from 'aws-amplify';
import useApolloClient from 'src/utils/useApolloClient';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ModalWindowProvider } from 'src/providers/ModalWindowProvider';
import { handleAuthEvents } from 'src/utils/appsyncApolloLink';
import TagManager from 'react-gtm-module';
import 'leaflet/dist/leaflet.css';

const AWS_CONFIG = window?.env?.aws_config;
const STRIPE_CONFIG = window?.env?.stripe_config;
const GTM_ID = window?.env?.gtm_id;

Hub.listen('auth', handleAuthEvents);

Amplify.configure({
	Auth: {
		// REQUIRED - Amazon Cognito Region
		region: AWS_CONFIG.aws_cognito_region,

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: AWS_CONFIG.aws_user_pools_id,

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: AWS_CONFIG.aws_user_pools_web_client_id,

		// OPTIONAL - Hosted UI configuration
		oauth: AWS_CONFIG.oauth,
	},
});

const stripePromise = loadStripe(STRIPE_CONFIG.stripe_publishableKey);

const url = AWS_CONFIG.aws_appsync_graphqlEndpoint;
const region = AWS_CONFIG.aws_appsync_region;

const tagManagerArgs = {
	gtmId: GTM_ID,
};

TagManager.initialize(tagManagerArgs);

const ApolloContainer: React.FC = () => {
	const client = useApolloClient({ url, region });

	return (
		<ApolloProvider client={client}>
			<Elements stripe={stripePromise}>
				<I18nextProvider i18n={i18n}>
					<Router history={history}>
						<AuthProvider>
							<UserProvider>
								<ModalWindowProvider>
									<UserContext.Consumer>
										{({ isCustomer }) => {
											const theme = isCustomer() ? customerTheme : taskerTheme;
											return (
												<ThemeProvider theme={theme}>
													<Grommet theme={theme}>
														<NotificationProvider>
															<App />
														</NotificationProvider>
													</Grommet>
												</ThemeProvider>
											);
										}}
									</UserContext.Consumer>
								</ModalWindowProvider>
							</UserProvider>
						</AuthProvider>
					</Router>
				</I18nextProvider>
			</Elements>
		</ApolloProvider>
	);
};

export default ApolloContainer;
