import { Avatar, Box, Button } from 'grommet';
import styled, { css } from 'styled-components';
import { mediaQueries, Screen } from '../../../utils/useMedia';

interface AvatarProps {
	isActive?: boolean;
}

export const LinkItem = styled(Box)`
	cursor: pointer;
	&:hover {
		svg {
			stroke: ${({ theme }) => theme?.global?.colors?.brand};
		}
	}
`;

export const AvatarWrapper = styled(Avatar)<AvatarProps>`
	position: relative;
	cursor: pointer;
	border: 2px solid ${({ theme }) => theme?.global?.colors?.brand};
	${({ isActive }) =>
		isActive &&
		css`
			box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.2) !important;
		`}
	&:hover {
		border: 2px solid ${({ theme }) => theme?.global?.colors?.brand};
	}
`;

export const Profile = styled(Box)`
	position: relative;

	${mediaQueries(Screen.tablet)`
		align-items: start;	
	`};
`;

export const ProfileMenu = styled(Box)`
	position: absolute;
	top: 65px;
	z-index: 5;
	background: ${({ theme }) => theme?.global?.colors?.white};
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
	max-width: initial;
	right: 0;
	min-width: 325px;

	${mediaQueries(Screen.tablet)`
		position: static;
	`};
`;

export const ProfileItem = styled(Box)`
	cursor: pointer;
	border-bottom: 1px solid ${({ theme }) => theme?.global?.colors?.['light-5']};
	&:hover {
		svg {
			stroke: ${({ theme }) => theme?.global?.colors?.brand};
		}
		color: ${({ theme }) => theme?.global?.colors?.brand};
	}
	&:last-child {
		border: none;
	}
`;

export const SwitchAccount = styled.div<{ selected?: boolean }>`
	padding: ${({ theme }) => theme?.global?.edgeSize?.xxsmall};
	border: 2px solid ${({ theme }) => theme?.global?.colors?.brand};
	border-radius: 10px;
	flex: 1 1 auto;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	background: ${({ theme, selected }) => (selected ? theme?.global?.colors?.brand : 'none')};
	${({ theme, selected }) => (selected ? theme?.global?.colors?.brandLight : 'none')};
	${({ theme, selected }) =>
		selected &&
		`
    color: ${theme?.global?.colors?.white};
    svg {
      stroke: ${theme?.global?.colors?.white};
    }
  `}

	&:hover {
		background: ${({ theme, selected }) => !selected && theme?.global?.colors?.brandLight};
	}
`;

export const SwitchClientAccount = styled(SwitchAccount)`
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
`;

export const SwitchTaskerAccount = styled(SwitchAccount)`
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-left: none;
`;

export const CreateOrderButton = styled(Button)`
	${mediaQueries(Screen.tablet)`
		color: #ffffff;
		margin-bottom: 16px;
		font-weight: 600;
	`};
`;
