import { BoxProps } from 'grommet';
import React from 'react';
import { ContainerWrapper } from 'src/components/Container/Container.styled';

type Props = BoxProps;

export const Container: React.FC<Props> = ({ children, ...rest }) => (
	<ContainerWrapper {...rest}>{children}</ContainerWrapper>
);

export default Container;
