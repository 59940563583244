import { Theme } from 'src/styles/theme';
import { css } from 'styled-components';

interface Sizes {
	phone: string;
	tablet: string;
	computer: string;
}

interface Media {
	phone: any;
	tablet: any;
	computer: any;
}

export const getScreenSize = (theme: Theme, screen: keyof Sizes): string =>
	theme?.global?.breakpoints?.[theme?.global?.deviceBreakpoints?.[screen] || 'small']?.value + 'px';

const sizes = (theme: Theme): Sizes => ({
	computer: getScreenSize(theme, 'computer'),
	tablet: getScreenSize(theme, 'tablet'),
	phone: getScreenSize(theme, 'phone'),
});

export const media = (theme: Theme): Media => {
	const computedSizes: Sizes = sizes(theme);
	return (Object.keys(computedSizes) as Array<keyof Sizes>).reduce(
		(accumulator: Media, label: keyof Sizes) => {
			accumulator[label] = (literals: TemplateStringsArray, ...placeholders: any[]) => css`
				@media only screen and (max-width: ${computedSizes[label]}) {
					${css(literals, ...placeholders)}
				}
			`;
			return accumulator;
		},
		{ phone: null, tablet: null, computer: null },
	);
};
