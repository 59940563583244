import React from 'react';
import LogoIcon from 'src/assets/logo.svg';
import { ReactSVG } from 'react-svg';
import { LogoWrapper } from 'src/components/Logo/Logo.styled';

interface Props {
	width?: string;
	height?: string;
	color?: string;
}

export const Logo: React.FC<Props> = ({ width = '30px', height = '30px', color = 'green' }) => (
	<LogoWrapper width={width} height={height} color={color}>
		<ReactSVG src={LogoIcon} />
	</LogoWrapper>
);

export default Logo;
