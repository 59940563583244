import React, { Fragment, Suspense, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Switch } from 'react-router';
import Header from 'src/components/Header/Header';
import { RoutesList } from 'src/components/Routes/Routes';
import { GlobalStyle, MainContainer } from 'src/features/App/App.styled';
import ModalWindow from 'src/components/ModalWindow/ModalWindow';
import { useTheme } from 'styled-components';
import { Box, ThemeType } from 'grommet';
import { UserContext } from 'src/providers/UserProvider';
import FulfillingBouncingCircleSpinner from 'src/components/Spinner/Spinner';
import queryString from 'query-string';
import { ModalType } from 'src/types/Auth.types';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import { AuthContext } from 'src/providers/AuthProvider';
import { useMedia, Screen } from 'src/utils/useMedia';

export const App: React.FC = () => {
	const theme: ThemeType = useTheme();
	const {
		state: { loadingUser },
		checkUserAuth,
	} = useContext(UserContext);
	const { showModalWindow } = useContext(ModalWindowContext);
	const {
		state: { resendVerificationCodeTimeout },
		updateState,
	} = useContext(AuthContext);
	const queryParams = queryString.parse(window.location.search);
	const isTablet = useMedia(Screen.tablet);

	useEffect(() => {
		if (queryParams.user_name && queryParams.confirmation_code) {
			showModalWindow({ type: ModalType.resetPassword });
		}
	}, [queryParams.user_name, queryParams.confirmation_code, showModalWindow]);

	useEffect(() => {
		checkUserAuth();
	}, [checkUserAuth]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (resendVerificationCodeTimeout > 0) {
				updateState({
					resendVerificationCodeTimeout: resendVerificationCodeTimeout - 1,
				});
			}
		}, 1000);
		return () => {
			clearInterval(interval);
		};
	}, [resendVerificationCodeTimeout, updateState]);

	return (
		<Fragment>
			<Helmet>
				<meta charSet="utf-8" />
				<title>DinDinDon</title>
			</Helmet>
			<Suspense fallback={null}>
				<Header />
			</Suspense>
			<MainContainer isTablet={isTablet}>
				{loadingUser ? (
					<Box align="center" justify="center" height="full">
						<FulfillingBouncingCircleSpinner color={theme.global?.colors?.brand} size={75} />
					</Box>
				) : (
					<Switch>
						<RoutesList />
						<Redirect path="*" to="/" />
					</Switch>
				)}
			</MainContainer>
			<Suspense fallback={null}>
				<ModalWindow />
			</Suspense>
			<GlobalStyle />
		</Fragment>
	);
};

export default App;
