import React, { createContext, useCallback, useReducer } from 'react';

interface Action {
	type: string;
	payload?: any;
}

interface State {
	confirmation_code: string | string[];
	user_name: string | string[];
	resendVerificationCodeTimeout: number;
}

interface AuthContextInterface {
	state: State;
	updateState(state: Partial<State>): void;
}

const defaultState: State = {
	confirmation_code: '',
	user_name: '',
	resendVerificationCodeTimeout: 0,
};

export const AuthContext = createContext<AuthContextInterface>({
	state: defaultState,
	updateState(): void {},
});

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'UPDATE_STATE':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export const AuthProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState);

	const updateState = useCallback((payload: Partial<State>) => {
		dispatch({ type: 'UPDATE_STATE', payload });
	}, []);

	const context: AuthContextInterface = {
		state,
		updateState,
	};

	return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
};
