import { Box, Layer } from 'grommet';
import { media } from 'src/utils/media';
import styled from 'styled-components';

interface Props {
	modalWidth: string;
}

export const ModalLayer = styled(Layer)`
	position: fixed;
	box-shadow: 4px 3px 5px 0 rgba(0, 0, 0, 0.3);
	overflow-x: hidden;
	overflow-y: scroll;
	${({ theme }) => media(theme).phone`
		-webkit-overflow-scrolling: touch;
		max-height: 100%;
  `}
`;

export const CloseIcon = styled.div`
	top: 25px;
	right: 25px;
	position: absolute;
	cursor: pointer;

	${({ theme }) => media(theme).phone`
    top: 15px;
  `}
`;

export const ContentWrapper = styled(Box)`
	form {
		width: 100%;
	}
`;

export const ModalAuthWrapper = styled(Box)<Props>`
	width: ${({ modalWidth }) => modalWidth};

	${({ theme }) => media(theme).phone`
    width: 100%;
    height: 100%;
  `}

	body {
		overflow: hidden;
	}
`;
