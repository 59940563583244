import { gql } from '@apollo/client';

export const GET_PROFILE = gql`
	query getProfile($id: String!, $category: String!) {
		getProfile(id: $id, category: $category) {
			id
			userId
			category
			location
			birthdate
			firstName
			lastName
			rating
			numberOfReviews
			rate
			description
			portfolioImages
			taskerVerification
		}
	}
`;

export const GET_PROFILES = gql`
	query getProfiles(
		$category: String!
		$location: String!
		$sortBy: UsersSortBy
		$fromLowest: Boolean
		$nextToken: AWSJSON
	) {
		getProfiles(
			category: $category
			location: $location
			sortBy: $sortBy
			fromLowest: $fromLowest
			nextToken: $nextToken
		) {
			nextToken
			profiles {
				id
				userId
				category
				location
				birthdate
				firstName
				lastName
				rating
				numberOfReviews
				rate
				description
				portfolioImages
				taskerVerification
			}
		}
	}
`;

export const GET_USER_PROFILES = gql`
	query getUserProfiles($id: String, $nextToken: AWSJSON, $limit: Int) {
		getUserProfiles(id: $id, nextToken: $nextToken, limit: $limit) {
			nextToken
			profiles {
				id
				userId
				category
				location
				birthdate
				firstName
				lastName
				rating
				numberOfReviews
				rate
				description
				portfolioImages
				taskerVerification
				postalCode
			}
		}
	}
`;

export const GET_USER = gql`
	query getUser($id: String) {
		getUser(id: $id) {
			id
			description
			categories
			location
			birthdate
			firstName
			lastName
			email
			isCustomer
			taskerVerification
			postalCode
			isBusiness
			kvkNumber
			businessName
		}
	}
`;

export const GET_SIGNED_URL = gql`
	query getSignedUrlUsers($profileImage: Boolean, $extension: String!) {
		getSignedUrlUsers(profileImage: $profileImage, extension: $extension)
	}
`;

export const GET_USER_PROFILES_BY_POSTAL_CODE = gql`
	query getProfilesByPostalCode(
		$category: String!
		$postalCode: String!
		$sortBy: UsersSortByPostalCode
		$fromLowest: Boolean
		$nextToken: AWSJSON
		$limit: Int
	) {
		getProfilesByPostalCode(
			category: $category
			postalCode: $postalCode
			sortBy: $sortBy
			fromLowest: $fromLowest
			nextToken: $nextToken
			limit: $limit
		) {
			nextToken
			profiles {
				id
				userId
				category
				location
				birthdate
				firstName
				lastName
				rating
				numberOfReviews
				rate
				description
				portfolioImages
				taskerVerification
				postalCode
				position {
					latitude
					longitude
				}
			}
		}
	}
`;
