import { Box, Text } from 'grommet';
import React, { ReactElement, useContext } from 'react';
import { FormClose as FormCloseIcon } from 'grommet-icons/icons';
import { CloseIcon } from 'src/components/ModalWindow/ModalWindow.styled';
import { ModalTitleWrapper } from 'src/components/ModalWindow/ModalTitle/ModalTitle.styled';
import Divider from 'src/components/Divider/Divider';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';

interface Props {
	title: string;
	icon?: ReactElement;
	handleClose?(): void;
}

const ModalTitle: React.FC<Props> = ({ title, icon, handleClose }) => {
	const { hideModalWindow } = useContext(ModalWindowContext);
	return (
		<ModalTitleWrapper>
			<Box
				pad={{ bottom: 'small', top: 'medium', horizontal: 'medium' }}
				justify="between"
				direction="row"
			>
				<Text size="medium" margin={{ right: 'xsmall' }}>
					{title}
				</Text>
				{icon}
			</Box>
			<Divider />
			<CloseIcon onClick={handleClose ?? hideModalWindow}>
				<FormCloseIcon />
			</CloseIcon>
		</ModalTitleWrapper>
	);
};

export default ModalTitle;
