import React, { Fragment, ReactElement, Suspense, useContext } from 'react';
import { match as IMatch, Redirect, Route, RouteProps, useRouteMatch } from 'react-router-dom';

import { IRoute, IRouteGuard } from 'src/global';
import { UserContext } from 'src/providers/UserProvider';
import { routes, routes as routesList } from 'src/routes';

interface RoutesListProps {
	routes?: IRoute[];
	match?: Partial<IMatch>;
}

const renderMergedProps: React.FC<React.ComponentType & RouteProps> = (
	component,
	...rest
): ReactElement => {
	const finalProps = Object.assign({}, ...rest);
	return React.createElement(component, finalProps);
};

const CustomRoute = ({ component, ...rest }: { component: React.ComponentType }): ReactElement => {
	return <Route {...rest} render={(routeProps) => renderMergedProps(component, routeProps)} />;
};

const routeGuard = (route: IRouteGuard, props: RouteProps): ReactElement => {
	const { component, ...rest } = props;
	const RouteComponent = route.component;
	const checkAuth = route.isPrivate && !route.isLoggedIn;
	const checkRole = route.roles && route.roles.length && !route.roles.includes(route.type);
	return (
		<Fragment>
			{checkAuth || checkRole ? (
				<Redirect to="/" />
			) : (
				<CustomRoute component={RouteComponent} {...rest} />
			)}
		</Fragment>
	);
};

const getRoutes = (routes: IRoute[], urls: string[]): IRoute[] => {
	if (!urls.length) return routes;
	const children = routes.find((route) => route.path === urls[0])?.children || [];
	if (!children.length) return routes;
	urls.shift();
	return getRoutes(children, urls);
};

export const RouterOutlet = () => {
	const { path } = useRouteMatch();
	const urls: string[] = path.split('/').filter((url) => !!url);
	const routesList = getRoutes(routes, urls);

	return <RoutesList routes={routesList} />;
};

export const RoutesList: React.FC<RoutesListProps> = ({ routes = routesList }) => {
	const {
		state: { isLoggedIn, type },
	} = useContext(UserContext);
	const { path } = useRouteMatch();

	return (
		<Suspense fallback={null}>
			{routes &&
				routes.map((route: IRoute) => {
					return (
						<Route
							key={route.id}
							exact={route.exact}
							path={path + route.path}
							render={(props: RouteProps) => routeGuard({ ...route, isLoggedIn, type }, props)}
						/>
					);
				})}
		</Suspense>
	);
};
