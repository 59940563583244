import styled from 'styled-components';
import { Avatar, Box } from 'grommet';

interface Props {
	color: string;
}

export const WrapperBadge = styled(Box)`
	position: relative;
`;

export const BadgeMargin = styled.div<Props>`
	display: block;
	width: 25px;
	height: 30px;
	background: ${({ theme, color }) => theme?.global?.colors?.[color] || color};
	position: absolute;
	left: -20px;
`;

export const Circle = styled.div<Props>`
	width: 50px;
	height: 50px;
	line-height: 1;
	border-radius: 50%;
	font-size: 14px;
	color: black;
	text-align: center;
	background: ${({ theme, color }) => theme?.global?.colors?.[color] || color};
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	z-index: 2;

	svg {
		width: 40px;
		height: 40px;
	}
`;

export const Badge = styled.div<Props>`
	background: ${({ theme, color }) => theme?.global?.colors?.[color] || color};
	display: flex;
	padding: 5px 10px 5px 10px;
	right: 5px;
	width: auto;
	position: relative;
	justify-content: flex-end;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	z-index: 1;
`;

export const CustomAvatar = styled(Avatar)<Props>`
	${({ theme, color, onClick }) =>
		onClick
			? `
		&:hover {
			outline: 2px solid ${theme?.global?.colors?.[color] || color};
	  	}
	`
			: ''}
`;
