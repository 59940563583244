import React from 'react';
import { StyledBurger } from './HamburgerMenu.styled';

interface Props {
	onClick(): void;
}

const HamburgerMenu: React.FC<Props> = ({ onClick }) => {
	return (
		<StyledBurger onClick={onClick}>
			<div />
			<div />
			<div />
		</StyledBurger>
	);
};

export default HamburgerMenu;
