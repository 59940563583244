import { InMemoryCache } from '@apollo/client';

export const ApolloCache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				getOrder(_, { args, toReference }) {
					return toReference({
						__typename: 'Order',
						id: args?.id,
					});
				},
				getProfiles: {
					keyArgs: ['category', 'location', 'sortBy', 'fromLowest'],
					merge(existing, incoming, { readField }) {
						const profiles = existing ? { ...existing.profiles } : {};
						if (incoming.profiles) {
							incoming.profiles.forEach((profile: any) => {
								profiles[String(readField('id', profile))] = profile;
							});
						}
						return {
							nextToken: incoming.nextToken,
							profiles,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								profiles: Object.values(existing.profiles),
							};
						}
					},
				},
				getOrders: {
					keyArgs: ['category', 'location', 'sortBy', 'fromLowest'],
					merge(existing, incoming, { readField }) {
						const orders = existing ? { ...existing.orders } : {};
						if (incoming.orders) {
							incoming.orders.forEach((order: any) => {
								orders[String(readField('id', order))] = order;
							});
						}
						return {
							nextToken: incoming.nextToken,
							orders,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								orders: Object.values(existing.orders),
							};
						}
					},
				},
				getCreatedOrders: {
					keyArgs: ['category'],
					merge(existing, incoming, { readField, args }) {
						const orders = existing && args?.nextToken ? { ...existing.orders } : {};
						if (incoming.orders) {
							incoming.orders.forEach((order: any) => {
								orders[String(readField('id', order))] = order;
							});
						}
						return {
							nextToken: incoming.nextToken,
							orders,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								orders: Object.values(existing.orders),
							};
						}
					},
				},
				getAcceptedOrders: {
					merge(existing, incoming, { readField }) {
						const orders = existing ? { ...existing.orders } : {};
						if (incoming.orders) {
							incoming.orders.forEach((order: any) => {
								orders[String(readField('id', order))] = order;
							});
						}
						return {
							nextToken: incoming.nextToken,
							orders,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								orders: Object.values(existing.orders),
							};
						}
					},
				},
				getRequests: {
					merge(existing, incoming, { readField }) {
						const requests = existing ? { ...existing.requests } : {};
						if (incoming.requests) {
							incoming.requests.forEach((request: any) => {
								requests[String(readField('id', request))] = request;
							});
						}
						return {
							nextToken: incoming.nextToken,
							requests,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								requests: Object.values(existing.requests),
							};
						}
					},
				},
				getOffers: {
					merge(existing, incoming, { readField }) {
						const offers = existing ? { ...existing.offers } : {};
						if (incoming.offers) {
							incoming.offers.forEach((offer: any) => {
								offers[String(readField('id', offer))] = offer;
							});
						}
						return {
							nextToken: incoming.nextToken,
							offers,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								offers: Object.values(existing.offers),
							};
						}
					},
				},
				getReviews: {
					keyArgs: ['category', 'taskerId'],
					merge(existing, incoming, { readField }) {
						const reviews = existing ? { ...existing.reviews } : {};
						if (incoming.reviews) {
							incoming.reviews.forEach((review: any) => {
								reviews[String(readField('id', review))] = review;
							});
						}
						return {
							nextToken: incoming.nextToken,
							reviews,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								reviews: Object.values(existing.reviews),
							};
						}
					},
				},
				getChatConfigs: {
					keyArgs: ['isCustomer'],
					merge(existing, incoming, { args, readField }) {
						// Wipe cache on the very first request
						const chatConfigs = existing && args?.nextToken ? { ...existing.chatConfigs } : {};
						if (incoming.chatConfigs) {
							if (incoming.nextToken === 'subscription') {
								return {
									nextToken: existing.nextToken,
									chatConfigs: incoming.chatConfigs,
								};
							} else {
								incoming.chatConfigs.forEach((chatConfig: any) => {
									chatConfigs[String(readField('id', chatConfig))] = chatConfig;
								});
							}
						}
						return {
							nextToken: incoming.nextToken,
							chatConfigs,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								chatConfigs: Object.values(existing.chatConfigs),
							};
						}
					},
				},
				getMessages: {
					keyArgs: ['chatId'],
					merge(existing, incoming, { args, readField }) {
						// Wipe cache on the very first request
						const messages = existing && args?.nextToken ? { ...existing.messages } : {};
						const sortedMessages: any = {};
						if (incoming.messages) {
							incoming.messages.forEach((message: any) => {
								messages[String(readField('id', message))] = message;
							});
							Object.keys(messages)
								.sort((a: any, b: any) => {
									return (
										new Date(String(readField('messageDate', messages[b]))).getTime() -
										new Date(String(readField('messageDate', messages[a]))).getTime()
									);
								})
								.forEach((key: any) => {
									sortedMessages[key] = messages[key];
								});
						}
						return {
							nextToken: incoming.nextToken,
							messages: sortedMessages,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								messages: Object.values(existing.messages),
							};
						}
					},
				},
				getCustomerPayments: {
					merge(existing, incoming, { readField }) {
						const customerPayments = existing ? { ...existing.customerPayments } : {};
						if (incoming.customerPayments) {
							incoming.customerPayments.forEach((customerPayment: any) => {
								customerPayments[String(readField('id', customerPayment))] = customerPayment;
							});
						}
						return {
							nextToken: incoming.nextToken,
							customerPayments,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								customerPayments: Object.values(existing.customerPayments),
							};
						}
					},
				},
				getTaskerPayouts: {
					merge(existing, incoming, { readField }) {
						const taskerPayouts = existing ? { ...existing.taskerPayouts } : {};
						if (incoming.taskerPayouts) {
							incoming.taskerPayouts.forEach((taskerPayout: any) => {
								taskerPayouts[String(readField('id', taskerPayout))] = taskerPayout;
							});
						}
						return {
							nextToken: incoming.nextToken,
							taskerPayoutsConfig: existing
								? existing.taskerPayoutsConfig
								: incoming.taskerPayoutsConfig,
							taskerPayouts,
						};
					},

					read(existing) {
						if (existing) {
							return {
								nextToken: existing.nextToken,
								taskerPayoutsConfig: existing.taskerPayoutsConfig,
								taskerPayouts: Object.values(existing.taskerPayouts),
							};
						}
					},
				},
			},
		},
	},
});

export default ApolloCache;
