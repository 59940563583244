// global styles with types
import { Anchor, Box, Text, Main } from 'grommet';
import styled, { createGlobalStyle } from 'styled-components';
import markerIcon from 'src/assets/markers/map-pin.svg';
import markerActiveIcon from 'src/assets/markers/map-pin-active.svg';

interface Props {
	isTablet: boolean;
}

export const GlobalStyle = createGlobalStyle`
	html, body, #root, #root > div {
		min-height: 100vh;
		margin: 0;
		font-weight: 500;
	}

	#root > div {
		display: flex;
		flex-direction: column;
	}

	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.js-focus-visible :focus:not([data-focus-visible-added]) {
		outline: none;
		box-shadow: none;
	}

	label {
		position: relative;
	}

	.leaflet-container {
		width: 100%;
		height: 100vh;
		z-index: 1;
	}

	.leaflet-popup-content {
		margin: 0;
	}

	.leaflet-popup-content-wrapper {
		padding: 0;
		border-radius: 0;
	}

	.leaflet-popup-content-wrapper, .leaflet-popup-tip {
		background: transparent;
		box-shadow: none;
	}

	.leaflet-popup-close-button {
		display: none;
	}

	// leaflet cluster
	.marker-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		width: 40px;
		height: 40px;
		background: url(${markerIcon}) no-repeat;
		background-size: contain;
	}

	.marker-count {
		margin-top: 3px;
		color: #000000;
	}

	.marker-active {
		background: url(${markerActiveIcon}) no-repeat;
	}

	.marker-icon {
		border-radius: 20px;
		border: 1px solid ${({ theme }) => theme?.global?.colors?.['light-3']};
		background: #ffffff;
		display: flex;
		align-items: center;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		justify-content: center;
		padding: 7px 14px;
	}

	.marker-price {
		font-weight: bold;
		font-size: 14px;
	}
	
	.marker-icon-active {
		background: #000000;
		color: #ffffff;
	}
`;

export const MainContainer = styled(Main)<Props>`
	padding-top: ${({ isTablet }) => (isTablet ? '68px' : '96px')};
	height: auto;
`;

export const CursorPointer = styled(Box)`
	cursor: pointer;
	&:hover {
		color: ${({ theme }) => theme?.global?.colors?.brand};
	}
`;

export const UnderlineText = styled(Text)`
	text-decoration: underline;
`;

export const UnderlineLink = styled(Anchor)`
	text-decoration: underline;
`;
