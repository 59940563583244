import { Box, Text } from 'grommet';
import { FormClose as FormCloseIcon } from 'grommet-icons';
import React, { useCallback, useContext, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { CursorPointer, UnderlineLink } from 'src/features/App/App.styled';
import { NotificationContext } from 'src/providers/NotificationProvider';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import { ModalType } from 'src/types/Auth.types';

const Notification: React.FC = () => {
	const {
		updateAuthNotification,
		state: { auth },
	} = useContext(NotificationContext);

	const { showModalWindow } = useContext(ModalWindowContext);

	const duration = 500;

	const defaultStyle = {
		transition: `opacity ${duration}ms ease-in-out`,
		opacity: 0,
	};

	const transitionStyles = {
		entering: { opacity: 1 },
		entered: { opacity: 1 },
		exiting: { opacity: 0 },
		exited: { opacity: 0 },
		unmounted: { opacity: 0 },
	};

	const notificationTypes = {
		error: {
			background: 'lightcoral',
		},
		success: {
			background: 'lightgreen',
		},
	};

	const closeNotification = useCallback(() => updateAuthNotification({ message: '' }), [
		updateAuthNotification,
	]);

	const handleContactUsClick = () => {
		showModalWindow({ type: ModalType.contactUs });
	};

	useEffect(() => {
		return () => closeNotification();
	}, [closeNotification]);

	return (
		<Transition in={!!auth?.message} timeout={duration}>
			{(state) => (
				<div
					style={{
						...defaultStyle,
						...transitionStyles[state],
					}}
				>
					{!!auth?.message && (
						<Box
							pad="medium"
							round="medium"
							margin={{ top: 'small' }}
							background={notificationTypes[auth.type].background}
							direction="row"
							justify="between"
							align="center"
						>
							<Text size="small" color="white">
								{auth.message} Have questions?{' '}
								<UnderlineLink
									onClick={handleContactUsClick}
									color="white"
									label="Contact us"
									href="#"
								/>
							</Text>

							<CursorPointer>
								<FormCloseIcon color="white" onClick={closeNotification} />
							</CursorPointer>
						</Box>
					)}
				</div>
			)}
		</Transition>
	);
};

export default Notification;
