import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
	query getNotifications($limit: Int, $nextToken: String) {
		getNotifications(limit: $limit, nextToken: $nextToken) {
			nextToken
			notifications {
				id
				timestamp
				eventType
				message
				isTaskerNotification
				event {
					... on Order {
						id
					}
					... on OrderRequest {
						id
					}
					... on Offer {
						id
					}
					... on User {
						id
					}
				}
			}
		}
	}
`;

export const GET_NOTIFICATIONS_CONFIG = gql`
	query getNotificationsConfig {
		getNotificationsConfig {
			hasNewNotifications
			hasNewMessages
		}
	}
`;
