import styled from 'styled-components';

interface MobileHeaderProps {
	isOpen: boolean;
}

export const MobileHeaderWrapper = styled.nav<MobileHeaderProps>`
	z-index: 5;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme?.global?.colors?.green};
	align-items: center;
	justify-content: center;
	position: fixed;

	opacity: 0;
	visibility: hidden;
	height: 0;
	transition: opacity 0.75s, visibility 0.75s, height 0.75s;
	overflow: hidden;

	${({ isOpen }) =>
		isOpen &&
		`   
    opacity: 1;
    visibility: visible;
    height: 100vh;
  `};
`;

export const MobileLink = styled.a`
	width: 100%;
	text-align: center;
	text-decoration: none;
	font-size: 2rem;
	color: ${({ theme }) => theme?.global?.colors?.white};
	font-weight: 600;
	padding: 0.5rem;
	border-radius: 3px;
	margin: 0 0.5rem;
`;
