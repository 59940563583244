import React from 'react';
import { ApolloClient } from '@apollo/client';
import { appsyncApolloLink } from 'src/utils/appsyncApolloLink';
import { AppSyncConfig } from 'src/types/AppSync.types';
import ApolloCache from 'src/graphql/ApolloCache';

const appsyncApolloClient = (appSyncConfig: AppSyncConfig) =>
	new ApolloClient({
		cache: ApolloCache,
		link: appsyncApolloLink(appSyncConfig),
	});

const useApolloClient = (appSyncConfig: AppSyncConfig) => {
	const [client] = React.useState(() => appsyncApolloClient(appSyncConfig));
	return client;
};

export default useApolloClient;
