import { Offer, Order, OrderRequest } from '../order/types';

export interface INotification {
	id: string;
	timestamp: string;
	message: string;
	event: Partial<Order & OrderRequest & Offer>;
	eventType: NotificationEventType;
	isTaskerNotification: boolean;
}

export enum NotificationEventType {
	request = 'request',
}
