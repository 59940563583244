import { Button, Header } from 'grommet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface HeaderProps {
	isTablet: boolean;
	scrollStarted: boolean;
}

export const HeaderWrapper = styled(Header)<HeaderProps>`
	position: fixed;
	z-index: 10;
	height: ${({ isTablet }) => (isTablet ? '68px' : '96px')};
	${(p) =>
		p.scrollStarted &&
		`
			height: ${p.isTablet ? '58px' : '70px'};
      box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1)
  `};
	transition: all 0.5s ease;
`;

export const HeaderLogo = styled(Link)`
	text-decoration: none;
`;

export const PrimaryButton = styled(Button)`
	color: ${({ theme }) => theme?.global?.colors?.white};
`;
