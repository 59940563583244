import { Box } from 'grommet';
import styled, { css } from 'styled-components';

interface Props {
	isShown?: boolean;
	zIndex?: number;
}

export const NotificationDot = styled(Box)<Props>`
	position: relative;

	${({ isShown, zIndex }) =>
		isShown &&
		css`
			&::after {
				content: '';
				position: absolute;
				height: 12px;
				width: 12px;
				top: -3px;
				right: -3px;
				background: red;
				border-radius: 20px;
				box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.2);
				z-index: ${zIndex};
			}
		`}
`;
