import styled from 'styled-components';
import { Box } from 'grommet';
import { mediaQueries, Screen } from 'src/utils/useMedia';

export const ContainerWrapper = styled(Box)`
	max-width: 1350px;
	width: 100%;
	padding: 0 36px;
	margin: 0 auto;

	${mediaQueries(Screen.tablet)`
		padding: 0 24px;
	`};
`;
