import React from 'react';
import { Text } from 'grommet';
import { useHistory } from 'react-router-dom';
import { CustomAvatar } from 'src/components/StripeWithCircle/StripeWithCircle.styled';
import { INotification, NotificationEventType } from 'src/graphql/notification/types';
import { profileImageGenerator } from 'src/utils/profileImageGenerator';
import { NotificationsListItemWrapper } from './NotificationsListItem.styled';

interface Props {
	notification: INotification;
}

const NotificationsListItem: React.FC<Props> = ({
	notification: { event, eventType, message },
}) => {
	const history = useHistory();

	const getNotificationData = () => {
		let link: string;
		let imageUrl: string;
		switch (eventType) {
			case NotificationEventType.request:
				link = `/order/${event.id}`;
				imageUrl = profileImageGenerator(event?.taskerId);
				break;
			default:
				link = '';
				imageUrl = '';
		}
		return {
			link,
			imageUrl,
		};
	};

	const notificationImageUrl = getNotificationData().imageUrl;

	const handleNotificationClick = () => history.push(getNotificationData().link);

	return (
		<NotificationsListItemWrapper
			round="small"
			pad="xsmall"
			direction="row"
			align="center"
			onClick={handleNotificationClick}
		>
			<CustomAvatar color="brand" size="medium" src={notificationImageUrl} />
			<Text margin={{ left: 'small' }}>{message}</Text>
		</NotificationsListItemWrapper>
	);
};

export default NotificationsListItem;
