import styled, { css } from 'styled-components';
import { Box } from 'grommet';

interface Props {
	isShown: boolean;
	scrollStarted: boolean;
}

export const NotificationsDropdownWrapper = styled(Box)<Props>`
	position: absolute;
	top: 65px;
	z-index: 5;
	background: ${({ theme }) => theme?.global?.colors?.white};
	box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.1);
	right: 0;
	height: 450px;
	width: 400px;
	max-width: none;
	overflow-y: auto;
	${({ isShown }) =>
		isShown &&
		css`
			transition: min-height 0.5s ease;
		`}
`;
