import { Anchor, Box, Nav, Text } from 'grommet';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { HeaderLogo, HeaderWrapper, PrimaryButton } from 'src/components/Header/Header.styled';
import { ModalType } from 'src/types/Auth.types';
import LoginToolbar from 'src/components/Header/LoginToolbar/LoginToolbar';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'src/providers/UserProvider';
import Container from 'src/components/Container/Container';
import Logo from 'src/components/Logo/Logo';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import AnchorLink from 'src/components/AnchorLink/AnchorLink';
import { Screen, useMedia } from 'src/utils/useMedia';
import HamburgerMenu from 'src/components/HamburgerMenu/HamburgerMenu';
import MobileHeader from 'src/components/MobileHeader/MobileHeader';
import { Close as CloseIcon } from 'grommet-icons';
import { NotificationDot } from '../NotificationDot/NotificationDot.styled';
import { useSubscription } from '@apollo/client';
import { NOTIFICATIONS_CONFIG_SUBSCRIPTION } from 'src/graphql/notification/subscriptions';

export const Header: React.FC = () => {
	const [scrollStarted, setScrollStarted] = useState(false);
	const { showModalWindow } = useContext(ModalWindowContext);
	const {
		state: { isLoggedIn, data: user },
	} = useContext(UserContext);
	const { t } = useTranslation(['header']);
	const isTablet = useMedia(Screen.tablet);
	const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(false);

	const { data: notificationsConfigData } = useSubscription(NOTIFICATIONS_CONFIG_SUBSCRIPTION, {
		variables: { id: user?.id || '' },
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		window.onscroll = () => {
			setScrollStarted(window.pageYOffset > 50);
		};
	}, []);

	const openModalAuth = (type: ModalType) => {
		showModalWindow({ type: type });
	};

	const handleHamburgerMenuClick = () => setIsMobileHeaderOpen(!isMobileHeaderOpen);

	return (
		<Fragment>
			<HeaderWrapper
				width="full"
				responsive={true}
				background="white"
				scrollStarted={scrollStarted}
				isTablet={isTablet}
			>
				<Container direction="row" justify="between">
					<Box direction="row" align="center">
						<HeaderLogo to="/" onClick={() => setIsMobileHeaderOpen(false)}>
							<Box direction="row" align="start">
								<Logo />
								<Text color="green" weight="bold" size="large">
									DinDinDon
								</Text>
							</Box>
						</HeaderLogo>
					</Box>
					{isTablet ? (
						<Fragment>
							{isMobileHeaderOpen ? (
								<CloseIcon onClick={handleHamburgerMenuClick} />
							) : (
								<NotificationDot
									isShown={notificationsConfigData?.updatedNotificationsConfig?.hasNewNotifications}
									zIndex={11}
								>
									<HamburgerMenu onClick={handleHamburgerMenuClick} />
								</NotificationDot>
							)}
						</Fragment>
					) : (
						<Nav direction="row" align="center">
							<Box direction="row" align="center">
								<AnchorLink to="/about-us" color="dark-2" margin={{ horizontal: 'small' }}>
									About us
								</AnchorLink>
								{isLoggedIn ? (
									<LoginToolbar
										hasNewNotifications={
											notificationsConfigData?.updatedNotificationsConfig?.hasNewNotifications
										}
										hasNewMessages={
											notificationsConfigData?.updatedNotificationsConfig?.hasNewMessages
										}
										scrollStarted={scrollStarted}
									/>
								) : (
									<Fragment>
										<AnchorLink to="/create-order" color="dark-2" margin={{ horizontal: 'small' }}>
											Create order
										</AnchorLink>
										<Box direction="row" align="center">
											<Anchor
												onClick={() => openModalAuth(ModalType.login)}
												color="dark-2"
												label={t(`header:logIn`)}
												margin={{ horizontal: 'small' }}
												href="#"
											/>
											<Anchor
												onClick={() => openModalAuth(ModalType.signUp)}
												color="dark-2"
												label={t(`header:signUp`)}
												margin={{ horizontal: 'small' }}
												href="#"
											/>
											<PrimaryButton
												margin={{ left: 'small' }}
												onClick={() => openModalAuth(ModalType.signUp)}
												primary={true}
												label="Become a tasker"
											/>
										</Box>
									</Fragment>
								)}
							</Box>
						</Nav>
					)}
				</Container>
			</HeaderWrapper>
			{isTablet && (
				<MobileHeader isOpen={isMobileHeaderOpen} onClick={() => setIsMobileHeaderOpen(false)} />
			)}
		</Fragment>
	);
};

export default Header;
