import React, { createContext, useCallback, useReducer } from 'react';
import { NotificationType } from 'src/types/Notification.types';

interface Action {
	type: string;
	payload: any;
}

interface State {
	auth: AuthNotification;
}

interface AuthNotification {
	message: string;
	type: NotificationType;
}

interface NotificationContextInterface {
	state: State;
	updateAuthNotification(state: Partial<AuthNotification>): void;
	clearAuthNotifications(): void;
}

const defaultState: State = {
	auth: { message: '', type: NotificationType.error },
};

export const NotificationContext = createContext<NotificationContextInterface>({
	state: defaultState,
	updateAuthNotification(): void {},
	clearAuthNotifications(): void {},
});

const reducer = (state: State, action: Action) => {
	switch (action.type) {
		case 'UPDATE_AUTH_NOTIFICATION':
			return { ...state, auth: { ...state.auth, ...action.payload } };
		default:
			return state;
	}
};

export const NotificationProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState);

	const updateAuthNotification = useCallback((state: AuthNotification) => {
		dispatch({
			type: 'UPDATE_AUTH_NOTIFICATION',
			payload: state,
		});
	}, []);

	const clearAuthNotifications = useCallback(() => {
		dispatch({
			type: 'UPDATE_AUTH_NOTIFICATION',
			payload: { message: '' },
		});
	}, []);

	const context: NotificationContextInterface = {
		state,
		updateAuthNotification,
		clearAuthNotifications,
	};

	return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>;
};
