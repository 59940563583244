export const parseTxtFileToJson = (file: string) =>
	JSON.parse(
		file
			.toString()
			.replace('var env = ', '')
			.replace(';', '')
			.replace(/'/g, '"')
			.replace(/([{,])(\s*)([A-Za-z0-9_-]+?)\s*:/g, '$1"$3":')
			.replace(/,(?!\s*?[{"'\w])/g, ''),
	);
