import React, { Suspense, useContext, useEffect, useState } from 'react';
import { ModalAuthWrapper, ModalLayer } from 'src/components/ModalWindow/ModalWindow.styled';
import { Box } from 'grommet';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import { ModalType } from 'src/types/Auth.types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ModalTitle from 'src/components/ModalWindow/ModalTitle/ModalTitle';
import Notification from 'src/components/Notification/Notification';
import { Screen, useMedia } from 'src/utils/useMedia';

const ModalLogin = React.lazy(() => import('src/components/ModalWindow/ModalLogin/ModalLogin'));
const ModalSignUp = React.lazy(() => import('src/components/ModalWindow/ModalSignUp/ModalSignUp'));
const ModalOrder = React.lazy(() => import('src/components/ModalWindow/ModalOrder/ModalOrder'));
const ModalForgotPassword = React.lazy(
	() => import('src/components/ModalWindow/ModalForgotPassword/ModalForgotPassword'),
);
const ModalResetPassword = React.lazy(
	() => import('src/components/ModalWindow/ModalResetPassword/ModalResetPassword'),
);
const ModalCompleteOrder = React.lazy(
	() => import('src/components/ModalWindow/ModalCompleteOrder/ModalCompleteOrder'),
);
const ModalOpenDispute = React.lazy(
	() => import('src/components/ModalWindow/ModalOpenDispute/ModalOpenDispute'),
);
const ModalRemoveOrder = React.lazy(
	() => import('src/components/ModalWindow/ModalRemoveOrder/ModalRemoveOrder'),
);
const ModalImage = React.lazy(() => import('src/components/ModalWindow/ModalImage/ModalImage'));
const ModalUploadImage = React.lazy(
	() => import('src/components/ModalWindow/ModalUploadImage/ModalUploadImage'),
);
const ModalRequest = React.lazy(
	() => import('src/components/ModalWindow/ModalRequest/ModalRequest'),
);
const ModalAddTaskerProfile = React.lazy(
	() => import('src/components/ModalWindow/ModalAddTaskerProfile/ModalAddTaskerProfile'),
);
const ModalAcceptOffer = React.lazy(
	() => import('src/components/ModalWindow/ModalAcceptOffer/ModalAcceptOffer'),
);
const ModalCancelRequest = React.lazy(
	() => import('src/components/ModalWindow/ModalCancelRequest/ModalCancelRequest'),
);
const ModalWindowEditFields = React.lazy(
	() => import('src/components/ModalWindow/ModalWindowEditFields/ModalWindowEditFields'),
);
const ModalPromotionRedirect = React.lazy(
	() => import('src/components/ModalWindow/ModalPromotionRedirect/ModalPromotionRedirect'),
);
const ModalWindowReadFields = React.lazy(
	() => import('src/components/ModalWindow/ModalWindowReadFields/ModalWIndowReadFields'),
);
const ModalPrompt = React.lazy(() => import('src/components/ModalWindow/ModalPrompt/ModalPrompt'));
const ModalRequestsList = React.lazy(
	() => import('src/components/ModalWindow/ModalRequestsList/ModalRequestsList'),
);
const ModalOffersList = React.lazy(
	() => import('src/components/ModalWindow/ModalOffersList/ModalOffersList'),
);
const ModalAcceptRequest = React.lazy(
	() => import('src/components/ModalWindow/ModalAcceptRequest/ModalAcceptRequest'),
);
const ModalAddPaymentMethod = React.lazy(
	() => import('src/components/ModalWindow/ModalAddPaymentMethod/ModalAddPaymentMethod'),
);
const ModalVerifyPhoneNumber = React.lazy(
	() => import('src/components/ModalWindow/ModalVerifyPhoneNumber/ModalVerifyPhoneNumber'),
);

const ModalContactUs = React.lazy(
	() => import('src/components/ModalWindow/ModalContactUs/ModalContactUs'),
);

const ModalWindow: React.FC = () => {
	const {
		state: { type, isShown, data },
		hideModalWindow,
	} = useContext(ModalWindowContext);
	const { t } = useTranslation(['auth']);
	const location = useLocation();
	const isMobile = useMedia(Screen.phone);
	const [locationHelper, setLocationHelper] = useState(location);
	const isTablet = useMedia(Screen.tablet);

	const modalCloseOnRedirect = (type: ModalType) => {
		switch (type) {
			case ModalType.requestsList:
				return true;
			case ModalType.offersList:
				return true;
			default:
				return false;
		}
	};

	useEffect(() => {
		if (modalCloseOnRedirect(type) && location !== locationHelper) {
			hideModalWindow();
		}
		setLocationHelper(location);
	}, [locationHelper, setLocationHelper, type, location, hideModalWindow]);

	const modalWidth = (type: ModalType) => {
		switch (type) {
			case ModalType.requestsList:
				return '720px';
			case ModalType.offersList:
				return '720px';
			default:
				return '535px';
		}
	};

	const renderComponent = (type: ModalType) => {
		switch (type) {
			case ModalType.login:
				return { title: t('auth:logIn.title'), component: <ModalLogin /> };
			case ModalType.signUp:
				return { title: t('auth:signUp.title'), component: <ModalSignUp /> };
			case ModalType.forgotPassword:
				return { title: t('auth:forgotPassword.title'), component: <ModalForgotPassword /> };
			case ModalType.resetPassword:
				return { title: t('auth:resetPassword.title'), component: <ModalResetPassword /> };
			case ModalType.order:
				return { title: t('auth:order.title'), component: <ModalOrder /> };
			case ModalType.completeOrder:
				return { title: t('auth:completeOrder.title'), component: <ModalCompleteOrder /> };
			case ModalType.openDispute:
				return { title: t('auth:openDispute.title'), component: <ModalOpenDispute /> };
			case ModalType.removeOrder:
				return { title: t('auth:removeOrder.title'), component: <ModalRemoveOrder /> };
			case ModalType.image:
				return { title: t('auth:image.title'), component: <ModalImage /> };
			case ModalType.uploadImage:
				return { title: t('auth:uploadOrderImage.title'), component: <ModalUploadImage /> };
			case ModalType.request:
				return { title: 'Request modal window', component: <ModalRequest /> };
			case ModalType.addTaskerProfile:
				return { title: 'Add tasker profile', component: <ModalAddTaskerProfile /> };
			case ModalType.acceptRequest:
				return { title: t('auth:acceptRequest.title'), component: <ModalAcceptRequest /> };
			case ModalType.acceptOffer:
				return { title: t('auth:acceptOffer.title'), component: <ModalAcceptOffer /> };
			case ModalType.cancelRequest:
				return { title: t('auth:cancelRequest.title'), component: <ModalCancelRequest /> };
			case ModalType.editFields:
				return { title: 'Edit fields', component: <ModalWindowEditFields /> };
			case ModalType.promotionRedirect:
				return { title: t('auth:promotionRedirect.title'), component: <ModalPromotionRedirect /> };
			case ModalType.readFields:
				return { title: t('auth:readFields.title'), component: <ModalWindowReadFields /> };
			case ModalType.prompt:
				return { title: t('Confirmation'), component: <ModalPrompt /> };
			case ModalType.requestsList:
				return { title: t('auth:requestsList.title'), component: <ModalRequestsList /> };
			case ModalType.offersList:
				return { title: t('auth:offersList.title'), component: <ModalOffersList /> };
			case ModalType.addPaymentMethod:
				return { title: t('auth:addPaymentMethod.title'), component: <ModalAddPaymentMethod /> };
			case ModalType.verifyPhoneNumber:
				return { title: t('auth:verifyPhoneNumber.title'), component: <ModalVerifyPhoneNumber /> };
			case ModalType.contactUs:
				return { title: t('auth:contactUs.title'), component: <ModalContactUs /> };
			case ModalType.custom:
				return { title: data.title, component: data.component };
			default:
				return { title: '', component: null };
		}
	};
	return (
		<Suspense fallback={false}>
			{isShown && (
				<ModalLayer position={isMobile ? 'right' : 'center'} onEsc={hideModalWindow} modal={true}>
					<ModalAuthWrapper modalWidth={modalWidth(type)}>
						<ModalTitle title={renderComponent(type).title} />
						<Box pad={{ horizontal: 'large' }}>
							<Notification />
						</Box>
						<Box pad={{ horizontal: isTablet ? 'medium' : 'large' }} width="full" height="100%">
							{renderComponent(type).component}
						</Box>
					</ModalAuthWrapper>
				</ModalLayer>
			)}
		</Suspense>
	);
};

export default ModalWindow;
