import React, { Fragment, useContext } from 'react';
import { MobileHeaderWrapper } from 'src/components/MobileHeader/MobileHeader.styled';
import AnchorLink from 'src/components/AnchorLink/AnchorLink';
import { UserContext } from 'src/providers/UserProvider';
import { ModalType } from 'src/types/Auth.types';
import { Anchor, Box, Button } from 'grommet';
import { ModalWindowContext } from 'src/providers/ModalWindowProvider';
import { useTranslation } from 'react-i18next';
import LoginToolbar from '../Header/LoginToolbar/LoginToolbar';

interface Props {
	isOpen?: boolean;

	onClick(): void;
}

export const MobileHeader: React.FC<Props> = ({ isOpen = false, onClick }) => {
	const {
		state: { isLoggedIn },
	} = useContext(UserContext);
	const { showModalWindow } = useContext(ModalWindowContext);
	const { t } = useTranslation(['header']);

	const openModalAuth = (type: ModalType) => {
		showModalWindow({ type: type });
	};

	const handleLogin = () => {
		openModalAuth(ModalType.login);
		onClick();
	};

	const handleSignUp = () => {
		openModalAuth(ModalType.signUp);
		onClick();
	};

	return (
		<MobileHeaderWrapper isOpen={isOpen}>
			{!isLoggedIn && (
				<Fragment>
					<AnchorLink to="/about-us" color="white" margin="small" onClick={onClick}>
						About us
					</AnchorLink>
					<AnchorLink to="/create-order" color="white" margin="small" onClick={onClick}>
						Create order
					</AnchorLink>
					<Anchor
						onClick={handleLogin}
						color="white"
						label={t(`header:logIn`)}
						margin="small"
						href="#"
					/>
					<Anchor
						onClick={handleSignUp}
						color="white"
						label={t(`header:signUp`)}
						margin="small"
						href="#"
					/>
					<Button
						margin="small"
						onClick={handleSignUp}
						primary={true}
						color="white"
						label="Become a tasker"
					/>
				</Fragment>
			)}
			{isLoggedIn && (
				<Box direction="column" justify="start">
					<LoginToolbar scrollStarted={false} onItemClick={onClick} />
					<AnchorLink to="/create-order" color="white" margin={{ top: 'small' }} onClick={onClick}>
						Create order
					</AnchorLink>
					<AnchorLink to="/about-us" color="white" margin={{ top: 'small' }} onClick={onClick}>
						About us
					</AnchorLink>
				</Box>
			)}
		</MobileHeaderWrapper>
	);
};

export default MobileHeader;
