import styled from 'styled-components';

interface Props {
	width: string;
	height: string;
	color: string;
}

export const LogoWrapper = styled.div<Props>`
	div {
		display: flex;
	}

	svg {
		width: ${({ width }) => width};
		height: ${({ height }) => height};
	}

	svg path {
		fill: ${({ color, theme }) => theme?.global?.colors?.[color]};
	}
`;
