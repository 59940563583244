import React from 'react';
import { IRoute } from 'src/global';

const Home = React.lazy(() => import('src/features/Home/Home'));
const CreateOrder = React.lazy(() => import('src/features/CreateOrder/CreateOrder'));
const OrdersList = React.lazy(
	() => import('src/features/Categories/CategoriesOrdersList/CategoriesOrdersList'),
);
const User = React.lazy(() => import('src/features/User/User'));
const UsersList = React.lazy(
	() => import('src/features/Categories/CategoriesUsersList/CategoriesUsersList'),
);
const Categories = React.lazy(() => import('src/features/Categories/Categories'));
const Order = React.lazy(() => import('src/features/Order/Order'));
const CategoriesList = React.lazy(
	() => import('src/features/Categories/CategoriesList/CategoriesList'),
);
const ChatsList = React.lazy(() => import('src/features/User/ChatsList/ChatsList'));
const UserRouter = React.lazy(() => import('src/features/User/UserRouter'));
const Promotion = React.lazy(() => import('src/features/User/Promotion/Promotion'));
const MyOrdersList = React.lazy(() => import('src/features/User/MyOrdersList/MyOrdersList'));
const AboutUs = React.lazy(() => import('src/features/AboutUs/AboutUs'));
const Finances = React.lazy(() => import('src/features/User/Finances/Finances'));

export const routes: IRoute[] = [
	{
		id: 'home',
		path: '',
		exact: true,
		component: Home,
	},
	{
		id: 'about-us',
		path: 'about-us',
		component: AboutUs,
	},
	{
		id: 'create-order',
		path: 'create-order',
		component: CreateOrder,
	},
	{
		id: 'promotion',
		path: 'promotion',
		component: Promotion,
		isPrivate: true,
	},
	{
		id: 'orders',
		path: 'orders',
		component: MyOrdersList,
		exact: true,
		isPrivate: true,
	},
	{
		id: 'chats',
		path: 'chats',
		component: ChatsList,
		exact: true,
		isPrivate: true,
	},
	{
		id: 'finances',
		path: 'finances',
		component: Finances,
		exact: true,
		isPrivate: true,
	},
	{
		id: 'categories',
		path: 'categories',
		component: Categories,
		children: [
			{
				id: 'orders',
				path: '/orders',
				component: CategoriesList,
				exact: true,
			},
			{
				id: 'categories-orders',
				path: '/orders/:categoryId',
				component: OrdersList,
				exact: true,
			},
			{
				id: 'users',
				path: '/users',
				component: CategoriesList,
				exact: true,
			},
			{
				id: 'categories-users',
				path: '/users/:categoryId',
				component: UsersList,
				exact: true,
			},
		],
	},
	{
		id: 'user',
		path: 'user',
		component: UserRouter,
		children: [
			{
				id: 'user',
				path: '/:userId',
				component: User,
				exact: true,
			},
		],
	},
	{
		id: 'order',
		path: 'order/:orderId',
		component: Order,
	},
];
