import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { theme as themeI, Theme } from 'src/styles/theme';

export enum Screen {
	phone = 'small',
	tablet = 'medium',
	computer = 'large',
}

export const useMedia = (screen: Screen) => {
	const theme: Theme = useTheme();
	const query = `(max-width: ${theme?.global?.breakpoints?.[screen || 'small']?.value}px)`;
	const [matches, setMatches] = useState(window.matchMedia(query).matches);

	useEffect(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}

		const listener = () => setMatches(media.matches);
		media.addEventListener('change', listener);

		return () => media.removeEventListener('change', listener);
	}, [query, matches]);

	return matches;
};

export const mediaQueries = (screen: Screen) => {
	return (style: TemplateStringsArray | string) => `
		@media (max-width: ${themeI?.global?.breakpoints?.[screen || 'small']?.value}px) { ${style} };
	`;
};
