import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(XHR)
	.init({
		/* default language when load the website in browser */
		lng: 'en',
		/* When react i18next not finding any language to as default in borwser */
		fallbackLng: 'en',
		/* debugger For Development environment */
		debug: true,
		// have a common namespace used around the full app
		ns: ['home', 'header'],
		defaultNS: 'home',

		interpolation: {
			escapeValue: false, // not needed for react
		},

		react: {
			useSuspense: true,
			wait: true,
		},
	});

export default i18n;
