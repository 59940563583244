import { Box } from 'grommet';
import styled from 'styled-components';

const Divider = styled(Box)`
	height: 1px;
	background: ${({ theme }) => theme?.global?.colors?.['light-5']};
	margin: ${({ theme }) => theme?.global?.edgeSize?.small} 0;
	width: 100%;
`;

export default Divider;
