export enum ModalType {
	login,
	signUp,
	forgotPassword,
	resetPassword,
	order,
	completeOrder,
	openDispute,
	removeOrder,
	image,
	uploadImage,
	none,
	request,
	addTaskerProfile,
	acceptRequest,
	acceptOffer,
	cancelRequest,
	editFields,
	promotionRedirect,
	readFields,
	prompt,
	requestsList,
	offersList,
	addPaymentMethod,
	verifyPhoneNumber,
	contactUs,
	custom,
}
