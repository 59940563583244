import { ApolloLink, HttpLink } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { AppSyncConfig } from 'src/types/AppSync.types';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { parseTxtFileToJson } from 'src/utils/parseTxtFileToJson';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { getMainDefinition } from '@apollo/client/utilities';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import queryString from 'query-string';
import { urlSafeDecode } from '@aws-amplify/core';

// Initialize cache variables
let isLoggedIn: boolean;
let apiKey: any = window.env.aws_config.aws_appsync_apiKey;
let cognitoToken: any;

// Main Apollo Link decclaration
export const appsyncApolloLink = (appSyncConfig: AppSyncConfig) =>
	ApolloLink.from([
		appsyncIsLoggedInContextLink,
		// Split requests depending on if user is logged in
		ApolloLink.split(
			(operation) => operation.getContext().isLoggedIn,
			appsyncCognitoLink(appSyncConfig),
			appsyncApiLink(appSyncConfig),
		),
		new HttpLink({ uri: appSyncConfig.url }),
	]);

// Check if user is logged in and populate cache on the first attempt
const appsyncIsLoggedInContextLink = setContext(() => {
	if (isLoggedIn) return { isLoggedIn };

	return Auth.currentAuthenticatedUser()
		.then(() => {
			isLoggedIn = true;
			return { isLoggedIn: true };
		})
		.catch(() => {
			isLoggedIn = false;
			return { isLoggedIn: false };
		});
});

// Create an AWS AuthLink that uses Cognito, suitable for signed-in users
const appsyncCognitoLink = (appSyncConfig: AppSyncConfig) =>
	// Split requests depending on the operation type
	ApolloLink.split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		ApolloLink.from([
			appsyncCognitoSubscriptionOnErrorLink,
			appsyncCognitoSubscriptionDelayContextLink,
			createCognitoSubscriptionLink(appSyncConfig),
		]),
		ApolloLink.from([
			appsyncCognitoAuthOnErrorLink,
			appsyncCognitoTokenRefreshContextLink,
			createCognitoAuthLink(appSyncConfig),
		]),
	);

// Handle AppSync Connection closed errors due to close/start websocket race condition
const appsyncCognitoSubscriptionOnErrorLink = onError(({ networkError, operation, forward }) => {
	// @ts-ignore: Connection closed error
	if (networkError?.errors[0].message === 'Connection closed') {
		return forward(operation);
	}
});

// Set delay on the re-openned connection requests due to close/start websocket race condition
const appsyncCognitoSubscriptionDelayContextLink = setContext(() => {
	new Promise((success) => {
		setTimeout(() => {
			success({});
		}, 1000);
	});
});

// Setup websocket connection
const createCognitoSubscriptionLink = (appSyncConfig: AppSyncConfig) =>
	createSubscriptionHandshakeLink({
		url: appSyncConfig.url,
		region: appSyncConfig.region,
		auth: {
			jwtToken: async () => {
				const result = await Auth.currentSession();
				return result.getIdToken().getJwtToken();
			},
			type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
		},
	});

// Handle Cognito token expiration error
const appsyncCognitoAuthOnErrorLink = onError(({ networkError, operation, forward }) => {
	if (
		networkError?.name === 'ServerError' &&
		'statusCode' in networkError &&
		networkError.statusCode === 401
	) {
		cognitoToken = null;
		return forward(operation);
	}
});

// Refresh Cognito token on the expiration
const appsyncCognitoTokenRefreshContextLink = setContext(() => {
	if (cognitoToken) return { cognitoToken };

	return Auth.currentSession().then((res) => {
		cognitoToken = res.getIdToken().getJwtToken();
		return {
			cognitoToken,
			headers: {
				Authorization: cognitoToken,
			},
		};
	});
});

// Setup Cognito GraphQL connection
const createCognitoAuthLink = (appSyncConfig: AppSyncConfig) =>
	(createAuthLink({
		url: appSyncConfig.url,
		region: appSyncConfig.region,
		auth: {
			jwtToken: () => cognitoToken,
			type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
		},
	}) as unknown) as ApolloLink;

// Create an AWS AuthLink that uses API key, suitable for non signed-in users
const appsyncApiLink = (appSyncConfig: AppSyncConfig) =>
	ApolloLink.from([
		appsyncApiAuthOnErrorLink,
		appsyncApiKeyRefreshContextLink,
		createApiAuthLink(appSyncConfig),
	]);

// Handle API token expiration error
const appsyncApiAuthOnErrorLink = onError(({ networkError, operation, forward }) => {
	if (
		networkError?.name === 'ServerError' &&
		'statusCode' in networkError &&
		networkError.statusCode === 401
	) {
		apiKey = null;
		return forward(operation);
	}
});

// Refresh API token on the expiration
const appsyncApiKeyRefreshContextLink = setContext(() => {
	if (apiKey) return { apiKey };

	return fetch('/env.js', {
		headers: {
			'Content-Type': 'application/javascript',
		},
	})
		.then((res) => res.text())
		.then((res) => parseTxtFileToJson(res))
		.then((env) => {
			apiKey = env.aws_config.aws_appsync_apiKey;
			return {
				apiKey,
				headers: {
					'X-Api-Key': apiKey,
				},
			};
		});
});

// Setup API GraphQL connection
const createApiAuthLink = (appSyncConfig: AppSyncConfig) =>
	(createAuthLink({
		url: appSyncConfig.url,
		region: appSyncConfig.region,
		auth: {
			apiKey: () => apiKey,
			type: AUTH_TYPE.API_KEY,
		},
	}) as unknown) as ApolloLink;

// Handle Idp logins
export const handleFacebookLogin = async (customState: string) => {
	await Auth.federatedSignIn({
		provider: CognitoHostedUIIdentityProvider.Facebook,
		customState: customState,
	});
};

// Hub auth listeners, to detect sign-in/out
export const handleAuthEvents = ({ payload }: any) => {
	switch (payload.event) {
		case 'signIn':
			cognitoToken = payload.data.signInUserSession.getIdToken().getJwtToken();
			isLoggedIn = true;
			break;
		case 'signOut':
			cognitoToken = null;
			isLoggedIn = false;
			break;
		case 'customOAuthState':
			window.location.href = payload.data;
			break;
		case 'configured':
			break;
		case 'parsingCallbackUrl':
			if (/already.found.an.entry.for.username.facebook/gi.test(payload.data.url)) {
				const customState =
					queryString.parse(payload.data.url).state?.toString().split('-').splice(1).join('-') ??
					'';
				handleFacebookLogin(urlSafeDecode(customState));
			}
			break;
		case 'signIn_failure':
			break;
		case 'signUp':
			break;
		default:
			break;
	}
};
