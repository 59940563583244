import React, { createContext, useCallback, useReducer } from 'react';
import { ModalType } from 'src/types/Auth.types';

interface Action {
	type: string;
	payload?: any;
}

interface State {
	isShown: boolean;
	type: ModalType;
	callback: ((params?: any) => void) | null;
	data: Record<string, any>;
}

interface ModalWindowContextInterface {
	state: State;
	showModalWindow(payload: Partial<State>): void;
	hideModalWindow(): void;
	updateState(state: Partial<State>): void;
	updateData(data: Record<string, any>): void;
}

const defaultState: State = {
	isShown: false,
	type: ModalType.none,
	callback: () => {},
	data: {},
};

export const ModalWindowContext = createContext<ModalWindowContextInterface>({
	state: defaultState,
	showModalWindow(): void {},
	hideModalWindow(): void {},
	updateState(): void {},
	updateData(): void {},
});

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'SHOW_MODAL_WINDOW':
			return {
				...state,
				...action.payload,
				isShown: true,
			};
		case 'HIDE_MODAL_WINDOW':
			return { ...state, isShown: false };
		case 'UPDATE_STATE':
			return { ...state, ...action.payload };
		case 'UPDATE_DATA':
			return { ...state, data: { ...state.data, ...action.payload } };
		default:
			return state;
	}
};

export const ModalWindowProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, defaultState);

	const showModalWindow = useCallback((payload: Partial<State>) => {
		dispatch({ type: 'SHOW_MODAL_WINDOW', payload });
	}, []);

	const hideModalWindow = useCallback(() => {
		dispatch({ type: 'HIDE_MODAL_WINDOW' });
	}, []);

	const updateState = useCallback((payload: Partial<State>) => {
		dispatch({ type: 'UPDATE_STATE', payload });
	}, []);

	const updateData = useCallback((payload: Record<string, any>) => {
		dispatch({ type: 'UPDATE_DATA', payload });
	}, []);

	const context: ModalWindowContextInterface = {
		state,
		showModalWindow,
		hideModalWindow,
		updateState,
		updateData,
	};

	return <ModalWindowContext.Provider value={context}>{children}</ModalWindowContext.Provider>;
};
