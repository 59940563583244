import { gql } from '@apollo/client';

export const NOTIFICATIONS_CONFIG_SUBSCRIPTION = gql`
	subscription updatedNotificationsConfig($id: String!) {
		updatedNotificationsConfig(id: $id) {
			id
			hasNewNotifications
			hasNewMessages
		}
	}
`;
